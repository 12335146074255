import React, { useEffect, useRef, useState } from "react";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Grid,
  Typography,
  Container,
  useTheme,
  Button,
  useMediaQuery,
  IconButton,
  darken,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Link,
  TextField,
} from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { setAlert } from "../features/slices/alertSlice";
import { useTranslation } from "react-i18next";
import { useLazyForgotPasswordSendPhoneOtpQuery, useLazyForgotPasswordValidatePhoneOtpQuery } from "../features/api/publicApiSlice";

import { useLazyGetForgotPasswordQuery } from "../features/api/publicApiSlice";
import FormPhoneNumber from "../components/form/FormPhoneNumber";

const ForgotPassword = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const mobileView = useMediaQuery(theme.breakpoints.down("sm"));

  const [phoneOpen, setPhoneOpen] = useState(false);
  const [seconds, setSeconds] = useState(null);
  const [phoneCode, setPhoneCode] = useState(['', '', '', '', '', '']);
  const [mobileNumber, setMobileNumber] = useState('');
  const inputRefs = useRef([]);

  const [
    sendOtp,
    {
      data: sendOtpData,
      // isFetching: isGetOtpFetching,
      isLoading: isGetOtpLoading,
      isError: isGetOtpError,
      isSuccess: isGetOtpSuccess,
      error: getOtpError,
    },
  ] = useLazyForgotPasswordSendPhoneOtpQuery({ prefetch: true });

  const [
    validateOtp,
    {
      data: validateOtpData,
      // isFetching: isGetOtpFetching,
      isLoading: isVerifyOtpLoading,
      isError: isVerifyOtpError,
      isSuccess: isVerifyOtpSuccess,
      error: verifyOtpError,
    },
  ] = useLazyForgotPasswordValidatePhoneOtpQuery({ prefetch: true });

  useEffect(() => {
    const timer = setInterval(() => {
      if (seconds > 0) {
        setSeconds(prevSeconds => prevSeconds - 1);
      }
    }, 1000);

    // Cleanup function to clear the interval when component unmounts or when seconds reach 0
    return () => clearInterval(timer);
  }, [seconds]);

  useEffect(() => {
    if (phoneCode.join('').length === 6) {
      handleVerifyPhone();
      inputRefs.current[0].focus();
    }
  }, [phoneCode]);

  const handleVerifyPhone = async () => {
    // Perform phone verification logic here
    let result = await validateOtp({
      phoneNumber: mobileNumber.replace(/[^A-Z0-9]+/gi, ""),
      otp: phoneCode.join("")
    });

    console.log(result);

    if (result.isSuccess) {
      handlePhoneClose();
      dispatch(
        setAlert({
          isOpen: true,
          message: t("walletcard.myaccountpage.verifyotpsuccess"),
          variant: "success",
        })
      );
      window.open(result?.data?.result)
    }
    else {
      dispatch(
        setAlert({
          isOpen: true,
          message: t("walletcard.myaccountpage.verifyotpfailed"),
          variant: "error",
        })
      );
      setPhoneCode(['', '', '', '', '', '']);
    }
  };

  const handlePhoneOpen = () => {
    setPhoneOpen(true);
    setSeconds(120);
  };

  const handlePhoneClose = () => {
    setPhoneOpen(false);
    setPhoneCode(['', '', '', '', '', '']);
  };

  const focusNextInput = (index) => {
    if (inputRefs.current[index + 1]) {
      inputRefs.current[index + 1].focus();
    }
  };

  const focusPreviousInput = (index) => {
    if (inputRefs.current[index - 1]) {
      inputRefs.current[index - 1].focus();
    }
  };

  const handleChangePhone = (index, value) => {
    if (value.length > 1) {
      value = value.charAt(0);
    }
    const newPhoneCode = [...phoneCode];
    newPhoneCode[index] = value;
    setPhoneCode(newPhoneCode);

    if (value !== '') {
      focusNextInput(index);
    } else {
      focusPreviousInput(index);
    }
  };

  const forgotPasswordSchema = yup.object().shape({
    mobileNumber: yup
      .string()
      .required(t("validation.mobilenum")),
  });

  const { control: forgotPasswordControl, handleSubmit } = useForm({
    resolver: yupResolver(forgotPasswordSchema),
  });

  const submitPhoneNumber = async (data) => {
    await sendOtp(data?.mobileNumber.replace(/[^A-Z0-9]+/gi, "")).then((result) => {
      if (result?.data?.responseCode === 200) {
        setMobileNumber(data?.mobileNumber);
        handlePhoneOpen();
      } else {
        console.log(result);
        forgotPasswordControl.setError("mobileNumber", {
          message: result?.error?.data?.message || result?.data?.message
        })
      };
    });
  };

  return (
    <>
      {mobileView && (
        <Grid
          container
          justify="center"
          py={1}
          backgroundColor={darken(theme.palette.background.paper, 0.4)}
        >
          <Grid item xs={4}>
            <IconButton onClick={() => navigate(-1)}>
              <ArrowBack />
            </IconButton>
          </Grid>
          <Grid item xs={4} alignSelf="center">
            <Typography
              textAlign="center"
              fontWeight={600}
              color={theme.palette.primary.main}
            >
              {t("forgotpassword.title")}
            </Typography>
          </Grid>
        </Grid>
      )}
      <Container>
        {!mobileView && (
          <Typography
            fontWeight={800}
            fontSize={14}
            color={theme.palette.primary.main}
            mt={5}
            mb={2}
          >
            {t("forgotpassword.title")}
          </Typography>
        )}
        <Box
          py={3}
          mt={mobileView && 5}
          px={mobileView ? 10 : 40}
          // mb={5}
          sx={{ backgroundColor: theme.palette.background.paper }}
        >
          <Typography color="white" fontSize={14}>
            {t("forgotpassword.desc")}
          </Typography>

          <Dialog open={phoneOpen} onClose={handlePhoneClose} BackdropProps={{ invisible: true }}>
            <DialogTitle sx={{ padding: 0 }}>
              <DialogActions>
                <Button onClick={handlePhoneClose}>X</Button>
              </DialogActions>
              <Typography
                sx={{
                  textAlign: 'center',
                  fontWeight: 'bold',
                  fontSize: '20px',
                  color: 'white',
                }}
              >
                Verify Mobile Number</Typography>
            </DialogTitle>
            <DialogContent>
              <Typography
                sx={{
                  textAlign: 'center',
                  fontSize: '12px',
                  color: 'white'
                }}
              >
                A 6-digit PIN number has been sent to your phone.</Typography>

              <Typography
                sx={{
                  textAlign: 'center',
                  fontWeight: 'bold',
                  fontSize: '16px',
                  color: 'white',
                  paddingTop: '5px'
                }}
              >
                {mobileNumber}
              </Typography>
              <br />
              <Grid container spacing={1} alignItems="center">
                {[...Array(6)].map((_, index) => (
                  <Grid item key={index}>
                    <TextField
                      variant="outlined"
                      size="small"
                      inputRef={(el) => (inputRefs.current[index] = el)}
                      value={phoneCode[index]}
                      onChange={(e) => handleChangePhone(index, e.target.value)}
                      inputProps={{
                        maxLength: 1,
                        style: { textAlign: 'center', width: '10px', height: '15px' }
                      }}
                    />
                  </Grid>
                ))}
              </Grid>
              <br />
              <DialogActions sx={{ justifyContent: 'center' }}>
                <Button variant="contained" color="error" onClick={handleVerifyPhone}>
                  VERIFY
                </Button>
              </DialogActions>
              <br />
              <Typography
                sx={{
                  textAlign: 'center',
                  fontSize: '12px',
                  color: 'white'
                }}>
                Experiencing any issues?{' '}
                <Link href='/contactus' style={{ color: 'red', textDecoration: 'none' }}>
                  Contact Customer Support
                </Link>
              </Typography>
              <br />
              <Typography
                sx={{
                  textAlign: 'center',
                  fontSize: '12px',
                  color: 'white'
                }}
              >
                Didn't receive the PIN number?{' '}
                <Typography
                  component="span"
                  sx={{ color: 'green', fontSize: '12px', textDecoration: 'none', cursor: seconds === 0 ? 'pointer' : 'not-allowed' }}
                  onClick={seconds === 0 ? handlePhoneOpen : null}
                >
                  Resend code ({seconds}s)
                </Typography>
              </Typography>


            </DialogContent>
          </Dialog>


          <form onSubmit={handleSubmit(submitPhoneNumber)}>
            <FormPhoneNumber
              label={t("mobileloginregister.mobilenumber")}
              defaultValue={""}
              control={forgotPasswordControl}
              controllerName="mobileNumber"
              size="small"
            />
            <Box display="flex" justifyContent="center" mt="30px">
              <Button variant="contained" type="submit">
                {t("forgotpassword.submit")}
              </Button>
            </Box>
          </form>
        </Box>
      </Container>
    </>
  );
};

export default ForgotPassword;

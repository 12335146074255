import { apiSlice } from "./apiSlice";

export const userApiSlice = apiSlice.injectEndpoints({
  tagTypes: [
    "Profile",
    "UpdateProfile",
    "RankInfo",
    "ShareLink",
    "Affiliate",
    "CurrentCredit",
    "BankAccounts",
    "PlayerBanks",
    "PlayerGetOtp",
    "PlayerVerifyOtp",
    "VerifyIdentity",
    "SaveTrackingData"
  ],
  endpoints: (builder) => ({
    listAffiliate: builder.mutation({
      query: (body) => ({
        url: "/user/api/v1/listAffiliate",
        method: "POST",
        body,
      }),
      providesTags: ["Affiliate"],
    }),
    getProfile: builder.query({
      query: () => "/user/api/v1/profile",
      providesTags: ["Profile"],
    }),
    updateProfile: builder.mutation({
      query: (body) => ({
        url: "/user/api/v1/profile",
        method: "PUT",
        body,
      }),
      providesTags: ["UpdateProfile"],
      invalidatesTag: ["Profile"],
    }),
    getRankInfo: builder.query({
      query: () => "/user/api/v1/rankInfo",
      providesTags: ["RankInfo"],
    }),
    getShareLink: builder.query({
      query: () => "/user/api/v1/shareLink",
      providesTags: ["ShareLink"],
    }),
    getBankAccounts: builder.query({
      query: () => "/user/api/v1/listBankAccounts",
      providesTags: ["BankAccounts"],
    }),
    playerBanks: builder.mutation({
      query: ( playerBank) => ({
        url: `/user/api/v1/addBankAccount`,
        method: "POST",
        body: playerBank,
      }),
      providesTags: ["PlayerBanks"],
    }),
    playerGetOtp: builder.query({
      query: (channel) => ({
        url: `/user/api/v1/getOtp?channel=${channel}`,
      }),
      providesTags: ["GetOtp"],
    }),
    playerVerifyOtp: builder.query({
      query: ({channel, otp}) => ({
        url: `/user/api/v1/verifyOtp?channel=${channel}&otp=${otp}`,
      }),
      providesTags: ["VerifyOtp"],
    }),
    verifyIdentity: builder.mutation({
      query: ({ identity }) => ({
        url: `/user/api/v1/verifyIdentity`,
        method: "POST",
        body: identity,
      }),
      providesTags: ["VerifyIdentity"],
    }),
    saveTrackingData: builder.mutation({
      query: (trackingData) => ({
        url: `/user/api/v1/saveTrackingData`,
        method: "POST",
        body: trackingData,
      }),
      providesTags: ["SaveTrackingData"],
    }),
  }),
});

export const {
  useGetProfileQuery,
  useUpdateProfileMutation,
  useGetRankInfoQuery,
  useGetShareLinkQuery,
  useGetBankAccountsQuery,
  usePlayerBanksMutation,
  useLazyPlayerGetOtpQuery,
  useLazyPlayerVerifyOtpQuery,
  useVerifyIdentityMutation,
  useSaveTrackingDataMutation,
} = userApiSlice;
